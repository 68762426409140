/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import middleware_62e91f6b from '/home/ubuntu/frontend/modules/@ergonode/core/src/middleware/privilegeRoutingCheck.global.js';

export default (ctx) => {
	middleware_62e91f6b(ctx);
};
