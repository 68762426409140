/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import store_5a87e56f from '/home/ubuntu/frontend/modules/@ergonode/ui/src/store/draggable/index.js';
import store_7480a55d from '/home/ubuntu/frontend/modules/@ergonode/attributes/src/store/attribute/index.js';
import store_4342e33a from '/home/ubuntu/frontend/modules/@ergonode/attribute-groups/src/store/attributeGroup/index.js';
import store_e4fd0f90 from '/home/ubuntu/frontend/modules/@ergonode/authentication/src/store/authentication/index.js';
import store_93a9b5d4 from '/home/ubuntu/frontend/modules/@ergonode/categories/src/store/category/index.js';
import store_45f86dfc from '/home/ubuntu/frontend/modules/@ergonode/category-trees/src/store/categoryTree/index.js';
import store_7f17007d from '/home/ubuntu/frontend/modules/@ergonode/comments/src/store/comment/index.js';
import store_bd744806 from '/home/ubuntu/frontend/modules/@ergonode/conditions/src/store/condition/index.js';
import store_50be6e5a from '/home/ubuntu/frontend/modules/@ergonode/core/src/store/unit/index.js';
import store_4bba77fc from '/home/ubuntu/frontend/modules/@ergonode/core/src/store/tab/index.js';
import store_1f7c4ef9 from '/home/ubuntu/frontend/modules/@ergonode/core/src/store/list/index.js';
import store_49194e72 from '/home/ubuntu/frontend/modules/@ergonode/core/src/store/feedback/index.js';
import store_ff899cba from '/home/ubuntu/frontend/modules/@ergonode/core/src/store/dictionaries/index.js';
import store_05d8a118 from '/home/ubuntu/frontend/modules/@ergonode/core/src/store/core/index.js';
import store_ce5d0a56 from '/home/ubuntu/frontend/modules/@ergonode/core/src/store/alert/index.js';
import store_2af09afd from '/home/ubuntu/frontend/modules/@ergonode/channels/src/store/channel/index.js';
import store_598ce140 from '/home/ubuntu/frontend/modules/@ergonode/media/src/store/media/index.js';
import store_e602a006 from '/home/ubuntu/frontend/modules/@ergonode/notifications/src/store/notification/index.js';
import store_18033cd2 from '/home/ubuntu/frontend/modules/@ergonode/collections/src/store/collection/index.js';
import store_3e102b06 from '/home/ubuntu/frontend/modules/@ergonode/products/src/store/product/index.js';
import store_d817155e from '/home/ubuntu/frontend/modules/@ergonode/product-statuses/src/store/productStatus/index.js';
import store_09a75238 from '/home/ubuntu/frontend/modules/@ergonode/product-templates/src/store/productTemplate/index.js';
import store_3ca02e06 from '/home/ubuntu/frontend/modules/@ergonode/segments/src/store/segment/index.js';
import store_0d6dc014 from '/home/ubuntu/frontend/modules/@ergonode/status-transitions/src/store/statusTransition/index.js';
import store_f3ec10b2 from '/home/ubuntu/frontend/modules/@ergonode/roles/src/store/role/index.js';
import store_e84deb86 from '/home/ubuntu/frontend/modules/@ergonode/users/src/store/user/index.js';
import store_8e7c56d0 from '/home/ubuntu/frontend/modules/@ergonode/import/src/store/import/index.js';
import store_78c63e80 from '/home/ubuntu/frontend/modules/@ergonode/workflow/src/store/workflowConditions/index.js';
import store_14821598 from '/home/ubuntu/frontend/modules/@ergonode/workflow/src/store/workflow/index.js';

export default {
	draggable: store_5a87e56f,
	attribute: store_7480a55d,
	attributeGroup: store_4342e33a,
	authentication: store_e4fd0f90,
	category: store_93a9b5d4,
	categoryTree: store_45f86dfc,
	comment: store_7f17007d,
	condition: store_bd744806,
	unit: store_50be6e5a,
	tab: store_4bba77fc,
	list: store_1f7c4ef9,
	feedback: store_49194e72,
	dictionaries: store_ff899cba,
	core: store_05d8a118,
	alert: store_ce5d0a56,
	channel: store_2af09afd,
	media: store_598ce140,
	notification: store_e602a006,
	collection: store_18033cd2,
	product: store_3e102b06,
	productStatus: store_d817155e,
	productTemplate: store_09a75238,
	segment: store_3ca02e06,
	statusTransition: store_0d6dc014,
	role: store_f3ec10b2,
	user: store_e84deb86,
	import: store_8e7c56d0,
	workflowConditions: store_78c63e80,
	workflow: store_14821598,
};
