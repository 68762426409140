/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import route_cd32b14e from '/home/ubuntu/frontend/modules/@ergonode/activity-logs/src/config/routes.js';
import route_17dbbe57 from '/home/ubuntu/frontend/modules/@ergonode/attributes/src/config/routes.js';
import route_90bfc96e from '/home/ubuntu/frontend/modules/@ergonode/attribute-groups/src/config/routes.js';
import route_2975cc54 from '/home/ubuntu/frontend/modules/@ergonode/authentication/src/config/routes.js';
import route_3f043eb2 from '/home/ubuntu/frontend/modules/@ergonode/categories/src/config/routes.js';
import route_0d26e608 from '/home/ubuntu/frontend/modules/@ergonode/category-trees/src/config/routes.js';
import route_5f7ffc0f from '/home/ubuntu/frontend/modules/@ergonode/core/src/config/routes.js';
import route_1414ced2 from '/home/ubuntu/frontend/modules/@ergonode/dashboard/src/config/routes.js';
import route_0291267e from '/home/ubuntu/frontend/modules/@ergonode/channels/src/config/routes.js';
import route_c1ff3cfc from '/home/ubuntu/frontend/modules/@ergonode/media/src/config/routes.js';
import route_17fe091e from '/home/ubuntu/frontend/modules/@ergonode/collections/src/config/routes.js';
import route_ead1052c from '/home/ubuntu/frontend/modules/@ergonode/products/src/config/routes.js';
import route_6ea02bd0 from '/home/ubuntu/frontend/modules/@ergonode/product-statuses/src/config/routes.js';
import route_1a3f082a from '/home/ubuntu/frontend/modules/@ergonode/product-templates/src/config/routes.js';
import route_b9dcf6a4 from '/home/ubuntu/frontend/modules/@ergonode/segments/src/config/routes.js';
import route_bf97e26a from '/home/ubuntu/frontend/modules/@ergonode/status-transitions/src/config/routes.js';
import route_0261976e from '/home/ubuntu/frontend/modules/@ergonode/roles/src/config/routes.js';
import route_14d37c44 from '/home/ubuntu/frontend/modules/@ergonode/users/src/config/routes.js';
import route_334d2ac9 from '/home/ubuntu/frontend/modules/@ergonode/import/src/config/routes.js';
import route_56908def from '/home/ubuntu/frontend/modules/@ergonode/workflow/src/config/routes.js';

export default {
	route_cd32b14e,
	route_17dbbe57,
	route_90bfc96e,
	route_2975cc54,
	route_3f043eb2,
	route_0d26e608,
	route_5f7ffc0f,
	route_1414ced2,
	route_0291267e,
	route_c1ff3cfc,
	route_17fe091e,
	route_ead1052c,
	route_6ea02bd0,
	route_1a3f082a,
	route_b9dcf6a4,
	route_bf97e26a,
	route_0261976e,
	route_14d37c44,
	route_334d2ac9,
	route_56908def,
};
